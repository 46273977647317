import React, { FC, memo } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";

import { isCurrent } from "utils/navigationUtil";

import { colorOrange } from "theme/colors";
import { IcnCollapseMode, IcnExpandMode } from "theme/icons";

const MenuSpecialTab: FC<MenuTabProps> = ({
  index,
  item,
  pageType,
  handleOnClick,
  handleMouseOver,
}): JSX.Element => {
  const label = (
    <Box display="flex" alignItems="center">
      <IcnCollapseMode />
      <Typography
        sx={{
          color: colorOrange,
          fontSize: "18px",
          fontWeight: "bold",
          paddingLeft: "5px",
        }}
      >
        {item.label}
      </Typography>
      <IcnExpandMode
        style={{
          width: "16px",
          height: "16px",
          margin: "4px",
          color: colorOrange,
        }}
      />
    </Box>
  );
  return (
    <Tab
      key={index}
      className={
        "tab-main-menu " + (isCurrent(pageType) === index ? "current" : "")
      }
      onMouseOver={() => handleMouseOver(index)}
      onClick={() => {
        handleOnClick(item.actionUrl);
      }}
      component="a"
      disableRipple={true}
      label={label}
      sx={{ opacity: 1, paddingX: 2 }}
    ></Tab>
  );
};
export default memo(MenuSpecialTab);
