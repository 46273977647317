import React, { FC, memo } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";

import { isCurrent } from "utils/navigationUtil";

import { IcnExpandMode } from "theme/icons";

const MenuTabButton: FC<MenuTabProps> = ({
  index,
  item,
  pageType,
  handleOnClick,
  handleMouseOver,
}): JSX.Element => {
  const label = (
    <Box display="flex">
      <Typography className="f-white">{item.label}</Typography>
      {item.linkedMenu && item.linkedMenu.length > 0 && (
        <IcnExpandMode
          style={{ width: "16px", height: "16px", margin: "4px 0 4px 3px" }}
        />
      )}
    </Box>
  );
  return (
    <Tab
      key={index}
      className={
        "tab-main-menu " + (isCurrent(pageType) === index ? "current" : "")
      }
      onMouseOver={() => handleMouseOver(index)}
      onClick={() => {
        handleOnClick(item.actionUrl);
      }}
      component="a"
      disableRipple={true}
      label={label}
      sx={{ opacity: 1, paddingX: 2.5 }}
    ></Tab>
  );
};
export default memo(MenuTabButton);
