import React, { FC, memo } from "react";

import Link from "next/link";

import ListItem from "@mui/material/ListItem";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";

import { colorText700 } from "theme/colors";

type MegaItem = {
  actionUrl: string;
  label: string;
  handleMouseOverSubMenu: () => void;
};

const MegaItem: FC<MegaItem> = ({
  actionUrl,
  label,
  handleMouseOverSubMenu,
}) => {
  return (
    <ListItem>
      <Link
        href={actionUrl}
        title={label}
        className={"fcx-menu-item"}
        onMouseOver={handleMouseOverSubMenu}
        style={{
          textDecorationColor: "transparent",
          textDecoration: "none!important",
        }}
      >
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: colorText700 }}
        >
          {label}
        </Typography>
        <ArrowForwardIosIcon className={"fcx-arrow"} />
      </Link>
    </ListItem>
  );
};
export default memo(MegaItem);
