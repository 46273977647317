import React, { FC, memo } from "react";

import Link from "next/link";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import { colorText800 } from "theme/colors";

type MegaListItemProps = { actionUrl: string; label: string };

const MegaListItem: FC<MegaListItemProps> = ({ actionUrl, label }) => {
  return (
    <ListItem
      sx={{
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <ArrowForwardIosIcon className={"fcx-link-arrow"} />
      <Link
        href={actionUrl}
        title={label}
        style={{
          textDecorationColor: "transparent",
          textDecoration: "none!important",
        }}
      >
        <Typography
          component={"span"}
          variant="subtitle2"
          sx={{
            color: colorText800,
            fontWeight: 400,
          }}
        >
          {label}
        </Typography>
      </Link>
    </ListItem>
  );
};
export default memo(MegaListItem);
