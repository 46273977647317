"use client";
import { FC } from "react";

import { useTranslations } from "next-intl";

import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { colorOrange } from "theme/colors";
import { IcnCart } from "theme/icons";

interface HeaderBtnCartProps {
  ouid?: string;
  cartItemCount?: number;
}

const HeaderBtnCart: FC<HeaderBtnCartProps> = ({ ouid, cartItemCount }) => {
  const t = useTranslations("common");
  return (
    <IconButton
      aria-label={t("cart-title")}
      href={ouid ? "/cart?ouid=" + ouid : "/cart"}
      size="large"
      sx={{
        borderRadius: "4px",
        paddingY: "7px",
        "&:hover": {
          backgroundColor: "rgb(31, 73, 155)",
        },
      }}
    >
      <Badge
        badgeContent={cartItemCount && cartItemCount}
        color="primary"
        overlap="circular"
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: colorOrange,
          },
        }}
      >
        <IcnCart className={"f-white"} />
      </Badge>
      <Typography
        className="f-white fcx-pl-10"
        sx={{ display: { lg: "block", xs: "none" } }}
      >
        {t("cart")}
      </Typography>
    </IconButton>
  );
};

export default HeaderBtnCart;
