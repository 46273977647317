import { FC, memo, ReactNode } from "react";

import Box from "@mui/material/Box";

type DropdownItemProps = {
  children: ReactNode;
  onClick?: () => void;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
};

const DropdownItem: FC<DropdownItemProps> = ({ children, onClick }) => {
  return (
    <Box
      className="menu-item mobile"
      onClick={onClick}
      sx={{
        cursor: "pointer",
      }}
    >
      {children}
    </Box>
  );
};
export default memo(DropdownItem);
