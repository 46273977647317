import { FC, memo } from "react";

import Box from "@mui/material/Box";

import HeaderBtnCourier from "components/Layout/Header/HeaderBtnCourier";
import HeaderBtnLabResults from "components/Layout/Header/HeaderBtnLabResults";
import HeaderVerticalSeparator from "components/Layout/Header/HeaderVerticalSeparator";
import HeaderBtnCart from "components/Layout/Header/HeaderBtnCart";

interface HeaderButtonsProps {
  ouid?: string;
  cartItemCount?: number;
}

const HeaderButtons: FC<HeaderButtonsProps> = ({ ouid, cartItemCount }) => {
  return (
    <>
      <Box alignItems="center" sx={{ display: { lg: "flex", xs: "none" } }}>
        <HeaderBtnCourier />
        <HeaderBtnLabResults />
        <HeaderVerticalSeparator />
      </Box>
      <HeaderBtnCart ouid={ouid} cartItemCount={cartItemCount} />
    </>
  );
};
export default memo(HeaderButtons);
