import { FC, memo } from "react";

import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";

type CloseIconProps = {
  handleClose: () => void;
};

const CloseIcon: FC<CloseIconProps> = ({ handleClose }) => {
  return (
    <IconButton
      aria-label="menu"
      className={"p-float-right"}
      onClick={() => handleClose()}
    >
      <CloseSharpIcon className="f-white" />
    </IconButton>
  );
};
export default memo(CloseIcon);
