import { FC, memo } from "react";

import Button from "@mui/material/Button";

import { colorText50 } from "theme/colors";
import { IcnBackArrow } from "theme/icons";

type PrevMenuButtonProps = { activeParent: Item[]; backMenu: () => void };

const PrevMenuButton: FC<PrevMenuButtonProps> = ({
  backMenu,
  activeParent,
}) => {
  return (
    <Button
      onClick={() => backMenu()}
      sx={{
        cursor: "pointer",
        color: colorText50,
        fontWeight: 600,
        fontSize: "1.1rem",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        textAlign: "left",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "&:hover": {
          textDecoration: "none",
        },
      }}
      startIcon={activeParent.length !== 0 ? <IcnBackArrow /> : null}
    >
      {activeParent.length !== 0
        ? activeParent[activeParent.length - 1].label
        : "Menu główne"}
    </Button>
  );
};
export default memo(PrevMenuButton);
