import { FC, memo } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { colorPrimary700 } from "theme/colors";
import { IcnExpandMode } from "theme/icons";

type MenuMobileItemWithChildrenProps = {
  label: string;
  onClick: () => void;
};

const MenuMobileItemWithChildren: FC<MenuMobileItemWithChildrenProps> = ({
  onClick,
  label,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Typography
        className="f-darker-gray"
        sx={{ fontSize: "1.1rem", font: "inherit" }}
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        title={label}
      >
        {label}
      </Typography>
      <IcnExpandMode
        style={{
          color: colorPrimary700,
          transform: "rotateZ(270deg)",
        }}
      />
    </Box>
  );
};
export default memo(MenuMobileItemWithChildren);
