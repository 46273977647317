"use client";
import { FC, useState } from "react";

import dynamic from "next/dynamic";

import { useTranslations } from "next-intl";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const HeaderLabResultsDialog = dynamic(
  () => import("components/Layout/Header/HeaderLabResultsDialog"),
);

const HeaderBtnLabResults: FC = () => {
  const t = useTranslations("common");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        variant="text"
        className={"fcx-lab-results f-dark-blue fcx-px-10 f-white"}
        sx={{
          borderRadius: "4px",
          paddingY: "7px",
          "&:hover": {
            backgroundColor: "rgb(31, 73, 155)",
          },
        }}
        disableElevation={true}
        onClick={handleClickOpen}
      >
        <Typography>{t("common-check-results")}</Typography>
      </Button>
      {open && <HeaderLabResultsDialog handleClose={handleClose} open={open} />}
    </>
  );
};

export default HeaderBtnLabResults;
