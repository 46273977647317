"use client";
import { FC, useState } from "react";

import dynamic from "next/dynamic";

import { useTranslations } from "next-intl";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const HeaderCourierDialog = dynamic(
  () => import("components/Layout/Header/HeaderCourierDialog"),
);

const HeaderBtnCourier: FC = () => {
  const [open, setOpen] = useState(false);
  const t = useTranslations("common");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        variant="text"
        className={"fcx-courier f-dark-blue fcx-px-10 f-white"}
        disableElevation={true}
        onClick={handleClickOpen}
        sx={{
          borderRadius: "4px",
          paddingY: "7px",
          "&:hover": {
            backgroundColor: "rgb(31, 73, 155)",
          },
        }}
      >
        <Typography>{t("common-shippment-order")}</Typography>
      </Button>
      {open && <HeaderCourierDialog handleClose={handleClose} open={open} />}
    </>
  );
};

export default HeaderBtnCourier;
