import { FC, memo } from "react";

import MenuMobileItemWithChildren from "components/Layout/Header/MobileNav/MenuMobileItemWithChildren";
import MenuMobileItem from "components/Layout/Header/MobileNav/MenuMobileItem";
import DropdownItem from "components/Layout/Header/MobileNav/DropdownItem";

type LinkElementProps = {
  item: Item;
  onClick?: () => void;
};

const LinkElement: FC<LinkElementProps> = ({
  item,
  onClick = () => {
    return;
  },
}): JSX.Element => {
  const hasChildren = item.linkedMenu && item.linkedMenu.length > 0;
  return hasChildren ? (
    <DropdownItem onClick={onClick}>
      <MenuMobileItemWithChildren label={item.label} onClick={onClick} />
    </DropdownItem>
  ) : (
    <DropdownItem>
      <MenuMobileItem label={item.label} actionUrl={item.actionUrl} />
    </DropdownItem>
  );
};
export default memo(LinkElement);
