"use client";
import { FC } from "react";

import Box from "@mui/material/Box";

import BannerIcons from "components/Banners/BannerIcons";

type TopBannerStickyProps = {
  bannerView: {
    colorFont: string;
    colorBackground: string;
    icon: string;
    iconColor: string;
    iconAlt: string;
    contents: string;
    linkColor: string;
  };
};

const TopBanner: FC<TopBannerStickyProps> = ({ bannerView }) => {
  const linkStyle = `<style>.topBanner a {color: ${bannerView?.linkColor}!important;}</style>`;

  return bannerView ? (
    <Box
      color={bannerView.colorFont}
      bgcolor={bannerView.colorBackground}
      className="topBanner"
    >
      {bannerView.icon && (
        <Box mr={"4px"}>
          <BannerIcons
            iconName={bannerView.icon}
            alt={bannerView.iconAlt}
            color={bannerView.iconColor}
          />
        </Box>
      )}
      <Box
        dangerouslySetInnerHTML={{
          __html: linkStyle + bannerView.contents,
        }}
      />
    </Box>
  ) : (
    <></>
  );
};
export default TopBanner;
