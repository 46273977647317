export const isCurrent = (pageType: string): number => {
  switch (pageType) {
    case "MAIN_PAGE":
      return 0;
    case "PRODUCT_COMMON_PAGE":
      return 1;
    case "PRODUCT_PAGE":
      return 1;
    case "ARTICLE_CATEGORY_PAGE":
      return 2;
    case "ARTICLE_PAGE":
      return 2;
    case "FAQ_PAGE":
      return 3;
    case "COMMON":
      return typeof window !== "undefined" &&
        window.location.pathname === "/kontakt"
        ? 4
        : -1;
    default:
      return -1;
  }
};

export const isMegaMenu = (el: Item): boolean => {
  let response = false;
  el.linkedMenu?.map((e) => {
    if (e.linkedMenu && e.linkedMenu.length > 0) {
      response = true;
    }
  });
  return response;
};
