import { useCallback, useState } from "react";

const useClientHeight = (): [
  number | null,
  (node: HTMLElement | null) => void,
] => {
  const [height, setRect] = useState<any>(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect()?.height);
    }
  }, []);
  return [height, ref];
};

export default useClientHeight;
