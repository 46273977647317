import { FC, memo } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { colorOrange } from "theme/colors";
import { IcnPhone } from "theme/icons";

const HeaderBtnCall: FC = () => {
  return (
    <Grid
      item
      display="flex"
      justifyContent="flex-end"
      sx={{ display: { lg: "flex", xs: "none" } }}
    >
      <Button
        href={"#cp-widget"}
        size={"small"}
        className={"fcx-phone f-white fcx-mr-10"}
        sx={{
          borderRadius: "4px",
          paddingY: "7px",
          "&:hover": {
            backgroundColor: "rgb(31, 73, 155)",
          },
        }}
      >
        <IcnPhone />
        <div className={"fcx-ml-10"}>
          <Typography className="f-bold" style={{ color: colorOrange }}>
            22 123 95 55
          </Typography>
        </div>
      </Button>
    </Grid>
  );
};
export default memo(HeaderBtnCall);
