import { FC, memo } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import CourierResultButtons from "components/Layout/Header/MobileNav/CourierResultButtons";
import PrevMenuButton from "components/Layout/Header/MobileNav/PrevMenuButton";
import CloseIcon from "components/Layout/Header/MobileNav/CloseIcon";

type MobileNavTopProps = {
  backMenu: () => void;
  activeParent: Item[];
  handleClose: () => void;
};

const MobileNavTop: FC<MobileNavTopProps> = ({
  backMenu,
  activeParent,
  handleClose,
}) => {
  return (
    <Box id="fcx-mobile-nav-top">
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          justifyContent="space-between"
          display="flex"
          alignItems="center"
          sx={{ p: "13px 10px 15px 20px" }}
        >
          <PrevMenuButton backMenu={backMenu} activeParent={activeParent} />
          <CloseIcon handleClose={handleClose} />
        </Grid>
        <CourierResultButtons />
      </Grid>
    </Box>
  );
};
export default memo(MobileNavTop);
