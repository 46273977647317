import React, { FC, Fragment, memo } from "react";

import { useTranslations } from "next-intl";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";

import MegaListItem from "./MegaListItem";

import MenuBanner from "components/Layout/Header/MenuBanner";
import SystemCategoryIcon from "components/CategoryIcons/SystemCategoryIcon";

import { colorPrimary100, colorText800 } from "theme/colors";

type MegaSubMenuProps = {
  e: Item;
  i: number;
  valueSubMenu: number | false;
};

const MegaSubMenu: FC<MegaSubMenuProps> = ({ e, i, valueSubMenu }) => {
  const t = useTranslations("common");
  if (e.linkedMenu && e.linkedMenu?.length > 0) {
    return (
      <Fragment key={`nav-${i}`}>
        <Grid
          item
          xs={6}
          style={{ display: valueSubMenu === i ? "block" : "none" }}
        >
          <Box className={"fcx-mega-submenu"}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ borderBottom: "1px solid" + colorText800, pb: "10px" }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={5}
                  marginTop={6}
                >
                  <Box
                    className="fcx-icon-box-mini-bg-no-margin"
                    sx={{
                      background: colorPrimary100,
                      borderRadius: "4px",
                      display: { xs: "none", sm: "inline-block" },
                    }}
                  >
                    {e.icon && (
                      <SystemCategoryIcon
                        iconName={e.icon}
                        iconColor={e.iconColor}
                        backgroundColor={e.backgroundColor}
                      />
                    )}
                  </Box>
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{ color: colorText800, pt: { xs: 1, sm: 4 } }}
                  >
                    {e.label}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={e.linkedMenu.length > 6 ? 6 : 12}
                className={"fcx-ml-none"}
              >
                <List>
                  {e.linkedMenu.map((l, i) => {
                    return (
                      i <= 5 && (
                        <MegaListItem
                          key={i}
                          actionUrl={l.actionUrl}
                          label={l.label}
                        />
                      )
                    );
                  })}
                </List>
              </Grid>
              {e.linkedMenu.length > 6 && (
                <Grid item xs={6}>
                  <List>
                    {e.linkedMenu.map((l, i) => {
                      return (
                        i > 5 &&
                        i < 14 && (
                          <MegaListItem
                            key={i}
                            actionUrl={l.actionUrl}
                            label={l.label}
                          />
                        )
                      );
                    })}
                  </List>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ p: "20px" }}
          style={{ display: valueSubMenu === i ? "block" : "none" }}
        >
          {e.banners?.length > 0 && (
            <Typography color={colorText800} variant="subtitle2" mt={5} mb={3}>
              {t("menu-check-also")}
            </Typography>
          )}
          {e.banners?.map(
            (item, i) => i < 2 && <MenuBanner banner={item} key={item.id} />,
          )}
        </Grid>
      </Fragment>
    );
  }
  return null;
};

export default memo(MegaSubMenu);
