import { FC, memo } from "react";

import Link from "next/link";

type MenuMobileItemProps = {
  actionUrl: string;
  label: string;
};

const MenuMobileItem: FC<MenuMobileItemProps> = ({ actionUrl, label }) => {
  return (
    <Link
      className="f-darker-gray"
      href={actionUrl}
      title={label}
      style={{ textDecoration: "inherit" }}
    >
      {label}
    </Link>
  );
};
export default memo(MenuMobileItem);
