import { FC, memo, useCallback, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import Box from "@mui/material/Box";

import MobileNavTop from "components/Layout/Header/MobileNav/MobileNavTop";
import LinkElement from "components/Layout/Header/MobileNav/LinkElement";

interface HeaderMobileNavProps {
  page: any;
  open: boolean;
  handleClose: () => void;
}

const HeaderMobileNav: FC<HeaderMobileNavProps> = ({
  page,
  open,
  handleClose,
}) => {
  const { menuItems } = page;
  const [activeParent, setActiveParent] = useState<Item[]>([]);

  const divMainRef = useRef(null);

  const getCurrentMenuNode = useCallback(() => {
    if (activeParent.length > 0) {
      return activeParent[activeParent.length - 1]?.linkedMenu;
    }
    return menuItems;
  }, [activeParent, menuItems]);

  const nextMenu = useCallback(
    (e: Item) => {
      setActiveParent([...activeParent, e]);
    },
    [activeParent],
  );

  const backMenu = useCallback(() => {
    if (activeParent.length > 0) {
      setActiveParent(activeParent.slice(0, -1));
    }
  }, [activeParent]);

  return (
    <Box id="fcx-mobile-nav">
      <MobileNavTop
        activeParent={activeParent}
        backMenu={backMenu}
        handleClose={handleClose}
      />
      <Box id="fcx-mobile-nav-bottom">
        <CSSTransition
          in={open}
          style={{ display: "block" }}
          timeout={3000}
          classNames="menu-primary"
          nodeRef={divMainRef}
          unmountOnExit
        >
          <div className="menu" ref={divMainRef}>
            {getCurrentMenuNode()?.map((e, i) => {
              return (
                <LinkElement key={i} item={e} onClick={() => nextMenu(e)} />
              );
            })}
          </div>
        </CSSTransition>
      </Box>
    </Box>
  );
};

export default memo(HeaderMobileNav);
