import React, { FC, memo } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Link } from "navigation";
import { BannerPageType } from "types/BannerTypes";

import { cleanUpImageUrl } from "utils/ImagesUrl";

import { colorPrimary500, colorText900 } from "theme/colors";

type MenuBannerPropsType = {
  banner: BannerPageType;
};

const MenuBanner: FC<MenuBannerPropsType> = ({ banner }) => {
  return (
    <Link href={banner.buttonHref} style={{ textDecoration: "none" }}>
      <Box
        sx={{
          backgroundColor: banner.colorBackground,
          width: "100%",
          height: "200px",
          mb: "20px",
          backgroundImage: `url(${
            process.env.NEXT_PUBLIC_ASSETS_URL
          }${cleanUpImageUrl(banner.image)})`,
          backgroundSize: "auto 280px",
          backgroundRepeat: "no-repeat",
          backgroundPosition:
            banner.imagePosition === "LEFT" ? "left" : "right",
        }}
        title={banner.contents}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          paddingLeft={"25px"}
          paddingRight={"25px"}
          maxWidth={"60%"}
          marginLeft={banner.imagePosition === "LEFT" ? "40%" : 0}
        >
          <Typography variant="h6" sx={{ color: colorText900 }} mb={2}>
            {banner.title}
          </Typography>
          <Typography sx={{ color: colorPrimary500 }}>
            {banner.buttonTitle}
          </Typography>
        </Stack>
      </Box>
    </Link>
  );
};
export default memo(MenuBanner);
