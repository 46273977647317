import React, { FC, useCallback } from "react";

import MegaMenu from "components/Layout/Header/DesktopNav/MegaMenu";
import SubMenu from "components/Layout/Header/DesktopNav/SubMenu";

import { isMegaMenu } from "utils/navigationUtil";

type MenuTabsProps = {
  menuItems: Item[];
  heightTopBanner?: number | null;
  value: number | false;
  setValue: (value: number | false) => void;
  valueSubMenu: number | false;
  setValueSubMenu: (value: number | false) => void;
};

const MenuTabs: FC<MenuTabsProps> = ({
  menuItems,
  heightTopBanner,
  value,
  setValue,
  valueSubMenu,
  setValueSubMenu,
}) => {
  const handleMouseLeave = useCallback(() => {
    setValue(false);
    setValueSubMenu(false);
  }, [setValue, setValueSubMenu]);

  const getTab = (el, value, i) => {
    if (el.type === "LINK") {
      return null;
    }
    if (isMegaMenu(el)) {
      return (
        <MegaMenu
          value={value}
          setValueSubMenu={setValueSubMenu}
          valueSubMenu={valueSubMenu}
          handleMouseLeave={handleMouseLeave}
          heightTopBanner={heightTopBanner}
          i={i}
          el={el}
          key={i}
        />
      );
    }
    if (el.type === "SUBMENU") {
      return (
        <SubMenu
          el={el}
          i={i}
          heightTopBanner={heightTopBanner}
          handleMouseLeave={handleMouseLeave}
          value={value}
          key={i}
        />
      );
    }
    return null;
  };

  return menuItems?.map((e, i) => {
    return getTab(e, value, i);
  });
};

export default MenuTabs;
