import { FC, memo } from "react";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";

type HeaderMobileMenuButtonProps = {
  openMobileMenu: () => void;
};

const HeaderMobileMenuButton: FC<HeaderMobileMenuButtonProps> = ({
  openMobileMenu,
}) => {
  return (
    <Grid item sx={{ display: { lg: "none", xs: "block" } }}>
      <IconButton
        aria-label="menu"
        className={"p-float-left fcx-mr-10"}
        onClick={openMobileMenu}
        size="large"
      >
        <Menu className="f-white" />
      </IconButton>
    </Grid>
  );
};
export default memo(HeaderMobileMenuButton);
