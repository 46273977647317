import { FC, memo } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import HeaderBtnCourier from "components/Layout/Header/HeaderBtnCourier";
import HeaderBtnLabResults from "components/Layout/Header/HeaderBtnLabResults";

const CourierResultButtons: FC = () => {
  return (
    <>
      <Grid item xs={6} sx={{ p: "10px 20px" }}>
        <Box className="fcx-btn-fullwidth">
          <HeaderBtnCourier />
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ p: "10px 20px" }}>
        <Box className="fcx-btn-fullwidth">
          <HeaderBtnLabResults />
        </Box>
      </Grid>
    </>
  );
};
export default memo(CourierResultButtons);
