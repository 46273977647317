import React, { FC } from "react";

import Box from "@mui/material/Box";

type NavTabPanel = {
  children: any;
  value: number | false;
  index: number;
  onMouseLeave?: () => void;
};

const NavTabPanel: FC<NavTabPanel> = ({
  children,
  value,
  index,
  onMouseLeave,
}) => {
  return (
    <div
      role="tabpanel"
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      style={{ display: value === index ? "block" : "none" }}
      onMouseLeave={onMouseLeave}
    >
      <Box>{children}</Box>
    </div>
  );
};

export default NavTabPanel;
