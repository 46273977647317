import { FC } from "react";

import Box from "@mui/material/Box";

import { colorGray100 } from "theme/colors";

const HeaderVerticalSeparator: FC = () => {
  return (
    <Box
      style={{
        height: "32px",
        width: "1px",
        position: "relative",
        borderRight: `1px solid ${colorGray100}`,
        boxSizing: "border-box",
        margin: "0 10px 0 10px",
      }}
    ></Box>
  );
};

export default HeaderVerticalSeparator;
