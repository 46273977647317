"use client";
import React, { FC, useCallback } from "react";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";

import MenuTab from "components/Layout/Header/DesktopNav/MenuTabButton";
import MenuSpecialTab from "components/Layout/Header/DesktopNav/MenuSpecialTab";
import MenuTabs from "components/Layout/Header/DesktopNav/MenuTabs";

interface HeaderNavProps {
  menuItems: Item[];
  pageType: string;
  valueSubMenu: number | false;
  value: number | false;
  setValue: (v: number | false) => void;
  setValueSubMenu: (v: number | false) => void;
  heightTopBanner: null | number;
}

const HeaderNav: FC<HeaderNavProps> = ({
  menuItems,
  pageType,
  valueSubMenu,
  setValueSubMenu,
  value,
  setValue,
  heightTopBanner,
}) => {
  const handleChange = useCallback(
    (event, newValue: number) => {
      setValue(newValue);
    },
    [setValue],
  );

  const handleMouseOver = useCallback(
    (i: number) => {
      if (value !== i) {
        setValue(i);
      }
    },
    [value, setValue],
  );

  const handleOnClick = useCallback((e: string) => {
    document.location.href = e;
  }, []);

  return (
    <Box className={"fcx-header-nav"}>
      <Tabs
        value={value}
        indicatorColor="primary"
        TabIndicatorProps={{
          style: {
            backgroundColor: "rgb(31, 73, 155)",
            height: "36px",
            borderRadius: "4px",
            top: "10px",
            zIndex: -1,
          },
        }}
        textColor="primary"
        onChange={handleChange}
        aria-label="nav tabs"
      >
        {menuItems.map((item, i) => {
          return item.actionUrl === "/badania" ? (
            <MenuSpecialTab
              key={i}
              index={i}
              item={item}
              pageType={pageType}
              handleOnClick={handleOnClick}
              handleMouseOver={handleMouseOver}
            />
          ) : (
            <MenuTab
              key={i}
              index={i}
              item={item}
              pageType={pageType}
              handleOnClick={handleOnClick}
              handleMouseOver={handleMouseOver}
            />
          );
        })}
      </Tabs>
      <MenuTabs
        menuItems={menuItems}
        value={value}
        setValue={setValue}
        heightTopBanner={heightTopBanner}
        valueSubMenu={valueSubMenu}
        setValueSubMenu={setValueSubMenu}
      />
    </Box>
  );
};

export default HeaderNav;
