"use client";

import { FC } from "react";

import FacebookRounded from "@mui/icons-material/FacebookRounded";
import { SvgIconProps } from "@mui/material/SvgIcon";

const FacebookIcon: FC<SvgIconProps> = (props) => {
  return <FacebookRounded {...props} />;
};

export default FacebookIcon;
